import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../api/stats';

export const getCampaignGroupsForStats = createAsyncThunk(
  'get/campaign-groups-for-stats',
  async (params) => api.getCampaignGroups(params)
);

export const getCampaignsStats = createAsyncThunk('get/campaigns-stats', async (params) =>
  api.getCampaignsStats(params)
);

export const getTopStates = createAsyncThunk('get/top-states', async (params) =>
  api.getStates(params)
);

export const getTopOS = createAsyncThunk('get/top-os', async (params) => api.getTopOS(params));

export const getTopPodcasts = createAsyncThunk('get/top-podcasts', async (params) =>
  api.getTopPodcasts(params)
);

const initialState = {
  totalStats: undefined,
  retargetingStats: undefined,
  sidecar: false,
  img: null,
  examplePlacement: [],
  topStates: {
    data: { records: [] },
    loading: false,
    error: null,
  },
  topOS: {
    data: { records: [] },
    loading: false,
    error: null,
  },
  topPodcasts: {
    data: [],
    loading: false,
    error: null,
  },
  campaignGroups: null,
  loading: false,
  error: null,
};

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    resetStats: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaignGroupsForStats.fulfilled, (state, action) => {
        state.loading = false;
        state.campaignGroups = action.payload;
      })
      .addCase(getCampaignGroupsForStats.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCampaignGroupsForStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCampaignsStats.fulfilled, (state, action) => {
        state.loading = false;
        state.totalStats = action.payload.totalStats;
        state.retargetingStats = action.payload.retargetingStats;
        state.sidecar = action.payload.sidecar;
        state.examplePlacement = action.payload.examplePlacement || [];
      })
      .addCase(getCampaignsStats.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCampaignsStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getTopStates.fulfilled, (state, action) => {
        state.topStates.loading = false;
        state.topStates.data = action.payload;
        state.topStates.error = null;
      })
      .addCase(getTopStates.pending, (state) => {
        state.topStates.loading = true;
        state.topStates.error = null;
      })
      .addCase(getTopStates.rejected, (state, action) => {
        if (!action.error.message?.includes('canceled')) {
          state.topStates.loading = false;
          state.topStates.error = action.error.message;
        }
      })
      .addCase(getTopOS.fulfilled, (state, action) => {
        state.topOS.loading = false;
        state.topOS.data = action.payload;
        state.topOS.error = null;
      })
      .addCase(getTopOS.pending, (state) => {
        state.topOS.loading = true;
        state.topOS.error = null;
      })
      .addCase(getTopOS.rejected, (state, action) => {
        if (!action.error.message?.includes('canceled')) {
          state.topOS.loading = false;
          state.topOS.error = action.error.message;
        }
      })
      .addCase(getTopPodcasts.fulfilled, (state, action) => {
        state.topPodcasts.loading = false;
        state.topPodcasts.data = action.payload;
        state.topPodcasts.error = null;
      })
      .addCase(getTopPodcasts.pending, (state) => {
        state.topPodcasts.loading = true;
        state.topPodcasts.error = null;
      })
      .addCase(getTopPodcasts.rejected, (state, action) => {
        if (!action.error.message?.includes('canceled')) {
          state.topPodcasts.loading = false;
          state.topPodcasts.error = action.error.message;
        }
      });
  },
});

export const selectStats = (state) => state.stats;
export const { resetStats } = statsSlice.actions;
export default statsSlice.reducer;
