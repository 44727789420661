import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { selectStats } from '../../redux/slices/statsSlice';
import { getSetupTopPodcastsChart } from '../../utils/charts';

const options = {
  responsive: true,
  interaction: {
    mode: 'x',
    intersect: false,
  },
  plugins: {
    legend: { labels: { color: '#b4b4b4' } },
  },
  stacked: false,
  scales: {
    x: {
      ticks: {
        color: '#b4b4b4',
      },
      grid: {
        drawBorder: false,
        color: () => '#232222',
      },
    },
    y: {
      reverse: true,
      max: 200,
      min: 1,
      ticks: {
        color: '#b4b4b4',
      },
      type: 'linear',
      display: true,
      position: 'left',
      grid: {
        drawBorder: false,
        color: () => '#232222',
      },
    },
  },
};

const initialState = {
  labels: [],
  datasets: [
    {
      label: '',
      backgroundColor: [],
      data: [],
      hoverOffset: 4,
    },
  ],
};

const PodcastsLineCharts = () => {
  const [chartData, setChartData] = useState({ ...initialState });
  const [countries, setCountries] = useState([]);
  const [chosenCountry, setChosenCountry] = useState('United States');
  const {
    topPodcasts: { data, loading },
  } = useSelector(selectStats);
  const chart = useRef(null);

  useEffect(() => {
    if (!data?.length) return;
    const countries = [...new Set(data.map((item) => item.country))];

    const sortedCountries = countries.includes('United States')
      ? ['United States', ...countries.filter((country) => country !== 'United States')]
      : countries;
    setChosenCountry(sortedCountries[0]);
    setCountries(sortedCountries);
    const setup = getSetupTopPodcastsChart(data, sortedCountries[0]);
    options.plugins.legend.display = setup.datasets.length !== 1;
    setChartData(setup);
  }, [data]);

  const handleChangeCountry = (event) => {
    setChosenCountry(event.target.value);
    const setup = getSetupTopPodcastsChart(data, event.target.value);
    options.plugins.legend.display = setup.datasets.length !== 1;
    setChartData(setup);
  };

  const renderChart = () =>
    (loading ? (
      <Grid container item md={12} justifyContent="center">
        <CircularProgress />
      </Grid>
    ) : (
      <>
        <Grid container item md={12}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <Select
              defaultValue={chosenCountry}
              value={chosenCountry}
              onChange={handleChangeCountry}
            >
              {countries.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid container item md={12}>
          <Line ref={chart} data={chartData} options={options} />
        </Grid>
      </>
    ));

  const renderNoData = () => (
    <Typography align="center" style={{ fontSize: 20, fontWeight: 'bold' }}>
      No Charting Data For This Podcast
    </Typography>
  );

  return !data.length && !loading ? renderNoData() : renderChart();
};

export default PodcastsLineCharts;
